import { Autocomplete, Box, Grid, InputAdornment, ListItemIcon, TextField, Typography } from "@mui/material";
import { JSX, useMemo } from "react";
import { MeasureDescriptor } from "../../../../../shared/reporting/api/biClient.types";
import { MeasuresStructure } from "../../../../api/biApi.types";
import { getIcon } from "../fields/FieldItem.helper";
import { useChartOfAccountsContext } from "./contexts/ChartOfAccountsContext";

interface MeasureSelectorProps {
  measures: MeasureDescriptor[];
  measuresStructure: MeasuresStructure;
  measure: MeasureDescriptor;
  readonly?: boolean;
  onSelect?: (measure: MeasureDescriptor) => void;
}
export default function MeasureSelector({ measures, measure, measuresStructure, onSelect }: MeasureSelectorProps) {
  const { resetSelectedAccounts } = useChartOfAccountsContext();

  const readOnly = onSelect === undefined;
  const measureList = useMemo(() => {
    const result: Measure[] = [];
    measures.forEach((measure) => {
      result.push(convertMeasure(measure, measuresStructure));
    });
    return result.sort(
      (a, b) =>
        measuresStructure.groups.findIndex((i) => i.caption === a.groupName) -
        measuresStructure.groups.findIndex((i) => i.caption === b.groupName)
    );
  }, [measures, measuresStructure]);

  return (
    <Grid container flexDirection="column" sx={{ gap: 1 }}>
      <Typography variant="subtitle1">Measure</Typography>
      <Autocomplete
        disablePortal
        disableClearable
        options={measureList}
        readOnly={readOnly}
        getOptionLabel={getOptionLabel}
        value={measureList.find((m) => m.name === measure.name)}
        slotProps={{ listbox: { style: { fontSize: "13px" } } }}
        sx={{
          fieldset: {
            borderColor: "#E5E6E9",
          },
          flex: 0.5,
          "& .MuiInputAdornment-positionStart": { ml: 0.75 },
          "& .MuiInputAdornment-root": { color: "#8E9BA9" },
          "& .MuiOutlinedInput-root:hover": { borderColor: "#E5E6E9" },
        }}
        groupBy={groupBy}
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{
              input: {
                ...params.InputProps,
                startAdornment: <InputAdornment position="start">{getIcon(measure)}</InputAdornment>,
                endAdornment: readOnly ? null : params.InputProps.endAdornment,
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.name}>
            <ListItemIcon
              sx={(theme) => ({
                minWidth: "20px",
                justifyContent: "center",
                color: option.custom ? theme.palette.info.main : theme.palette.secondary.light,
              })}
            >
              {option.icon}
            </ListItemIcon>
            <Typography
              variant="caption"
              sx={{ flexGrow: 1, pointerEvents: "none", pl: 1, color: (theme) => theme.palette.text.primary }}
            >
              {option.caption}
            </Typography>
          </Box>
        )}
        onChange={(_, value) => {
          const measure = measures.find((m) => m.name === value?.name);
          if (measure) {
            onSelect?.(measure);
            resetSelectedAccounts();
          }
        }}
      />
    </Grid>
  );
}

type Measure = { icon: JSX.Element; name: string; caption: string; groupName: string; custom: boolean };

function convertMeasure(measure: MeasureDescriptor, measuresStructure: MeasuresStructure): Measure {
  const groupName =
    measure.group || measuresStructure.groups.find((g) => g.names.includes(measure.name))?.caption || "";
  return {
    icon: getIcon(measure),
    name: measure.name,
    caption: measure.caption,
    groupName: groupName,
    custom: measure.custom,
  };
}

function getOptionLabel(option: Measure): string {
  return option.caption;
}
function groupBy(option: Measure): string {
  return option.groupName;
}
