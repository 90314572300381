import { ListItem } from "@mui/material";
import { JSX, useCallback, useEffect, useRef, useState } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ShowFieldOptionsSettings } from "../../../Types";
import { FieldItemProps } from "../types/areaFiledItem.types";
import { DraggableFieldType, FieldWrapper } from "../types/dropField.types.ts";
import AreaFieldItem from "./AreaFieldItem";
import { DEFAULT_AREA_FIELD_ITEM_HEIGHT } from "./AreaFieldsHelper";

export interface AreaFieldItemProps<T extends DraggableFieldType> {
  areaField: FieldWrapper<T>;
  canBeRemoved: boolean;
  acceptedDropTypes: string[];
  canDrop: boolean;
  draggingItemMovedFromInitialPosition: boolean;
  addShifting: boolean;
  hideDraggingItem: boolean;
  createItem?: (props: FieldItemProps<T>) => JSX.Element;
  formatCaption?: (field: T) => JSX.Element | undefined;
  onRemoveItem: (item: T) => void;
  onEndReordering: (field: FieldWrapper<T>) => void;
  showOptions?: (settings: ShowFieldOptionsSettings<T>) => void;
  onItemHovered: (item: FieldWrapper<T>) => void;
}

export const AreaFieldItemContainer = <T extends DraggableFieldType>(props: AreaFieldItemProps<T>) => {
  const {
    areaField,
    canBeRemoved,
    acceptedDropTypes,
    canDrop,
    createItem,
    formatCaption,
    onRemoveItem,
    onEndReordering,
    showOptions,
    onItemHovered,
    draggingItemMovedFromInitialPosition,
    addShifting,
    hideDraggingItem,
  } = props;
  const [removed, setRemoved] = useState(false);
  const listItemRef = useRef<HTMLLIElement>(null);

  const collect = useCallback((monitor: DragSourceMonitor<FieldWrapper<T>, unknown>) => {
    return {
      isDragging: monitor.isDragging(),
    };
  }, []);

  const end = useCallback(
    (item: FieldWrapper<T>, monitor: DragSourceMonitor<FieldWrapper<T>, unknown>) => {
      let droppedAtSameContainer = true;
      const dropResult = monitor.getDropResult() as { dropContainerType: string };
      if (dropResult?.dropContainerType) {
        droppedAtSameContainer = dropResult.dropContainerType === areaField.type;
      }
      if (monitor.didDrop() && droppedAtSameContainer) {
        onEndReordering(item);
      } else {
        const fieldWrapper = item as FieldWrapper<T>;
        if (fieldWrapper.field !== undefined && monitor.getTargetIds().length === 0) {
          onRemoveItem(fieldWrapper.field);
          setRemoved(true);
        }
      }
    },
    [areaField.type, onEndReordering, onRemoveItem]
  );

  const hover = useCallback(
    (_: FieldWrapper<T>, monitor: DropTargetMonitor<FieldWrapper<T>>) => {
      if (monitor.isOver()) {
        onItemHovered(areaField);
      }
    },
    [areaField, onItemHovered]
  );

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: areaField.type,
      item: areaField,
      collect,
      end,
    }),
    [end, areaField]
  );

  const [, drop] = useDrop(() => {
    return {
      accept: [areaField.type, ...acceptedDropTypes],
      hover,
      canDrop: () => canDrop,
    };
  }, [areaField.type, hover, canDrop]);

  drag(drop(listItemRef));

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  const onShowOptions = useCallback(
    (settings: ShowFieldOptionsSettings<T>) => {
      if (!showOptions) return;
      const ref = settings.showOnlySelected ? listItemRef.current : settings.ref;
      if (!ref) return;
      showOptions({ field: areaField.field, ref, showOnlySelected: settings.showOnlySelected });
    },
    [areaField.field, showOptions]
  );

  return (
    <ListItem
      role="Handle"
      ref={listItemRef}
      data-index={areaField.index}
      data-is-dragging={isDragging}
      data-is-shifted={addShifting}
      sx={{
        display: removed || hideDraggingItem ? "none" : "flex",
        flexDirection: "column",
        border: "1px solid #E5E6E9",
        borderRadius: "2px",
        p: 0,
        bgcolor: "#fff",
        overflow: "hidden",
        height: !isDragging ? "auto" : `${DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
        minHeight: `${DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
        "&:hover": {
          cursor: "grab",
        },
      }}
      style={{
        ...(addShifting && {
          transform: "translate(0px, 37px)",
        }),
        ...(draggingItemMovedFromInitialPosition
          ? { transition: "transform 0.2s cubic-bezier(0.2, 0, 0, 1)" }
          : { transition: "none 0s ease 0s" }),
      }}
    >
      {createItem &&
        createItem({
          field: areaField.field,
          canBeRemoved,
          onRemoveItem,
          formatCaption,
          onShowOptions,
        })}

      {!createItem && (
        <AreaFieldItem
          field={areaField.field}
          canBeRemoved={canBeRemoved}
          onRemoveItem={onRemoveItem}
          formatCaption={formatCaption}
          onShowOptions={onShowOptions}
        />
      )}
    </ListItem>
  );
};
