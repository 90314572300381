import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Grid2, ListItemIcon, SxProps, Theme, Typography } from "@mui/material";
import { useCallback } from "react";
import {
  DimensionDescriptor,
  MeasureDescriptor,
  MetaDescriptorBase,
} from "../../../../../../shared/reporting/api/biClient.types";
import { makeLighterBackgroundFromColor } from "../../../../../../shared/utilities/colorHelper";
import { getIcon } from "../FieldItem.helper";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";

interface Props<T extends MetaDescriptorBase> {
  field: T;
  sx?: SxProps<Theme>;
  caption?: string;
}

export const DimensionFieldItem = <T extends MetaDescriptorBase>({ field, caption }: Props<T>) => {
  const getColor = useCallback(
    (theme: Theme) => {
      const measure = field as unknown as MeasureDescriptor;
      return measure.custom === true ? theme.palette.info.main : theme.palette.secondary.light;
    },
    [field]
  );

  const text = caption || field.caption;

  return (
    <Grid2 container alignItems="center" sx={{ flexWrap: "nowrap", width: "100%" }}>
      <ListItemIcon sx={(theme) => ({ minWidth: "20px", justifyContent: "center", color: getColor(theme) })}>
        {getIcon(field)}
      </ListItemIcon>
      <TypographyTooltipEllipsis
        key={text}
        tooltipProps={{ disableInteractive: true }}
        typographyProps={{
          sx: { flexGrow: 1, pl: 1, color: "text.primary" },
          variant: "caption",
        }}
        text={text}
      />
      {(field as unknown as DimensionDescriptor).isNew && (
        <Typography
          variant="caption"
          sx={(theme) => ({
            color: theme.palette.info.main,
            fontWeight: 500,
            fontSize: "12px",
            borderRadius: "64px",
            backgroundColor: makeLighterBackgroundFromColor(theme.palette.info.main),
            py: 0.5,
            px: 1,
            my: -0.5,
          })}
        >
          New
        </Typography>
      )}
      <ListItemIcon sx={{ minWidth: "20px" }}>
        <DragIndicatorIcon color="disabled" />
      </ListItemIcon>
    </Grid2>
  );
};

export default DimensionFieldItem;
