import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Box, Checkbox, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DimensionDictionary } from "../../../../../../shared/reporting/api/biClient.types";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import { ConditionField } from "../../../Types";
import { ChartOfAccountsContextItem, useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";
import { getFilterFieldSubItemUniqueId } from "../helpers/chartOfAccountsContextHelper";
import FieldItemShowOnGridButton from "./FieldItemShowOnGridButton";
import FilterFieldChartOfAccountItem from "./FilterFieldChartOfAccountItem";

interface FilterItemProps {
  condition: ConditionField;
  chartOfAccountItems?: ChartOfAccountsItem[];
  dictionary: DimensionDictionary;
  parentNode: ChartOfAccountsContextItem | null;
}
export default function FilterFieldSubItem({
  condition,
  chartOfAccountItems,
  parentNode,
  dictionary,
}: FilterItemProps) {
  const [conditionExpanded, setConditionExpanded] = useState(false);
  const [fieldIsHovered, setFieldIsHovered] = useState(false);
  const { root, appendChild, getNode, onSelect, scrollToAccountNo } = useChartOfAccountsContext();
  const id = getFilterFieldSubItemUniqueId(dictionary, parentNode?.identifier);

  const currentNode = useMemo(() => {
    if (!parentNode) return undefined;
    return getNode(id, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, id, root]);

  const { value, description } = useMemo(() => {
    return {
      value: dictionary[condition.meta.availableFields[0] || ""] as string,
      description: dictionary[condition.meta.availableFields[1] || ""] as string,
    };
  }, [dictionary, condition]);

  const chartOfAccounts = useMemo(() => {
    if (condition.meta.name === "AccountCategory") {
      return chartOfAccountItems?.filter((item) => item.accountCategory === value);
    }
    return undefined;
  }, [condition, chartOfAccountItems, value]);

  useEffect(() => {
    if (!parentNode) return;
    appendChild(id, parentNode, condition.meta.name !== "AccountCategory" ? value : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, id]);

  return (
    <>
      <Grid
        container
        sx={(theme) => ({
          justifyContent: "start",
          flexFlow: "row nowrap",
          bgcolor: currentNode?.checked ? "rgba(0, 168, 102, 0.08)" : "inherit",
          height: "28px",
          pl: 4.5,
          pr: 1,
          ":hover": {
            cursor: "pointer",
            background: theme.palette.action.hover,
          },
        })}
        onMouseEnter={() => setFieldIsHovered(true)}
        onMouseLeave={() => setFieldIsHovered(false)}
      >
        <Grid sx={{ display: "flex", alignItems: "center", height: "28px" }}>
          {currentNode && (
            <Checkbox
              indeterminate={currentNode.indeterminate === true}
              checked={currentNode.checked === true}
              sx={{ width: "28px", height: "28px" }}
              onChange={(e) => {
                onSelect(currentNode.identifier, e.target.checked);
              }}
            />
          )}
          {chartOfAccounts && chartOfAccounts.length > 0 && (
            <IconButton
              sx={{ width: "28px", height: "28px" }}
              color="secondary"
              onClick={() => setConditionExpanded((prev) => !prev)}
            >
              {conditionExpanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
            </IconButton>
          )}
        </Grid>
        <Grid
          container
          sx={{ flexDirection: "column", flex: 1, position: "relative" }}
          onClick={() => currentNode && onSelect(currentNode.identifier, !currentNode.checked)}
        >
          <Box sx={{ position: "absolute", height: "100%", width: "100%", overflow: "hidden" }}>
            <Grid
              container
              sx={(theme) => ({
                height: "28px",
                color: theme.palette.text.primary,
                gap: 1,
                alignItems: "center",
                "& .MuiGrid-item": {
                  flex: 0.5,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  "& .MuiTypography-root": {
                    whiteSpace: "nowrap",
                    px: 1,
                  },
                },
              })}
            >
              <Grid item>
                <Typography variant="caption">{value?.toString()}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">{description}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid>
          <FieldItemShowOnGridButton
            show={fieldIsHovered}
            onClick={() => currentNode && scrollToAccountNo(currentNode)}
          />
        </Grid>
      </Grid>
      <Collapse orientation="vertical" in={conditionExpanded}>
        {chartOfAccounts &&
          currentNode &&
          chartOfAccounts.map((item, i) => (
            <FilterFieldChartOfAccountItem index={i} key={`coa-${i}`} item={item} parentNode={currentNode} />
          ))}
      </Collapse>
    </>
  );
}
