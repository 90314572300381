import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { JSX } from "react";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { codeItemStyles, getHighlightedSyntax } from "./helpers/codeHelper";
import PrettyCodeDisplay from "./PrettyCodeDisplay";

interface Props {
  title: string;
  code: string;
  isLoading: boolean;
  Header: JSX.Element;
}
const CodeContainer = ({ code, isLoading, Header }: Props) => {
  const rows = getHighlightedSyntax(code, codeItemStyles);

  return (
    <Stack gap={1}>
      <Stack alignItems="center" justifyContent="space-between" flexDirection="row">
        {Header}
      </Stack>
      <Stack
        sx={{
          border: "1px solid #EAECF0",
          borderRadius: 1,
          bgcolor: grey[50],
          overflow: "hidden",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <Box sx={{ width: "100%", height: "100%", overflowY: "auto", position: "relative" }}>
          {isLoading && <InlineLoader overlay />}
          <Box display={"flex"} height={"100%"} width={"100%"}>
            <Stack height={"100%"} flex={"1 0 auto"}>
              <PrettyCodeDisplay rows={rows} />
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CodeContainer;
