import {
  ChartConfiguration,
  ChartStyleSettings,
  ConditionConfiguration,
  ConditionDescriptor,
  DimensionDescriptor,
  DimensionField,
  MeasureDateRange,
  PivotConfiguration,
  PivotGeneralSettings,
  PivotValuesRequest,
  ReportConfiguration,
  ReportField,
  ReportType,
  TabularConfiguration,
  TabularGeneralSettings,
} from "../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../shared/utilities/cloneDeep";
import { defined } from "../../../../shared/utilities/typeHelper";
import { TabularValueRequest } from "../../../api/biApi.types";
import { toAmountType, toCalculateBy } from "../common/customMeasure/utilities/measureConverter";
import { getValidConditionDescriptors } from "../common/utilities/getValidConditions";
import { ConditionField } from "../Types";
import { isAggregation, isDimension, isMeasure } from "./fieldsHelper";

enum DimensionNames {
  PostingDate = "PostingDate",
  TransactionDate = "TransactionDate",
}

export enum InvalidConfigurationReason {
  ConditionsFieldAndCustomConditions = 1,
  FieldAndCustomConditions = 2,
  MultipleFields = 3,
  MultipleCustomConditions = 4,
  NoDateFields = 5,
  MandatoryFieldIsNotSelected = 6,
}

export function isTabularConfigurationValid(
  conditions: ConditionField[],
  fields: ReportField[],
  dimensions: DimensionDescriptor[]
): MeasureValidReturnType & { measure?: string } {
  if (fields.length === 0) return { valid: false };
  const validConditions = getValidConditionDescriptors(conditions);
  const dimensionFields = getDimensionFields(fields);
  const measureFields = getMeasureFields(fields, conditions);

  const invalidMeasure = measureFields
    .map((mf) => {
      const valid = isMeasureValid(validConditions, dimensionFields, mf, dimensions);
      return { ...valid, measure: mf.guid };
    })
    .find((result) => result.valid === false);

  if (invalidMeasure !== undefined) {
    return invalidMeasure;
  }
  if (
    !areMandatoryFieldsSet(
      conditions,
      fields.flatMap((f) => f.config.customConditions || [])
    )
  ) {
    return { valid: false, reason: InvalidConfigurationReason.MandatoryFieldIsNotSelected };
  }
  return { valid: true };
}

export function isPivotConfigurationValid(
  conditions: ConditionField[],
  fields: DimensionField[],
  measures: ReportField[],
  dimensions: DimensionDescriptor[]
): MeasureValidReturnType & { measure?: string } {
  if (fields.length === 0) return { valid: false };
  const validConditions = getValidConditionDescriptors(conditions);
  const measureFields = measures.map((m) => getMeasureField(conditions, m));

  const invalidMeasure = measureFields
    .map((mf) => {
      const valid = isMeasureValid(validConditions, fields, mf, dimensions);
      return { ...valid, measure: mf.guid };
    })
    .find((result) => result.valid === false);

  if (invalidMeasure !== undefined) {
    return invalidMeasure;
  }

  if (
    !areMandatoryFieldsSet(
      conditions,
      measures.flatMap((f) => f.config.customConditions || [])
    )
  ) {
    return { valid: false, reason: InvalidConfigurationReason.MandatoryFieldIsNotSelected };
  }
  return { valid: true };
}

export function isMeasureConfigurationValid(
  conditions: ConditionField[],
  fields: DimensionField[],
  dimensions: DimensionDescriptor[],
  value: ReportField
): MeasureValidReturnType {
  const validConditions = getValidConditionDescriptors(conditions);
  const measureField = getMeasureField(conditions, value);

  const valid = isMeasureValid(validConditions, fields, measureField, dimensions);
  return valid;
}

export function isMeasureValid(
  conditions: ConditionDescriptor[],
  fields: DimensionField[],
  measureField: TabularValueRequest,
  dimensions: DimensionDescriptor[]
): MeasureValidReturnType {
  const conditionDates = getDateConditions(conditions, dimensions);
  const dateFields = getDateFields(fields);
  const customConditionDate = getDateCustomConditions(measureField, dimensions);

  if (dateFields.length > 0) {
    const referenceDateField = getDimensionDescriptor(defined(dateFields[0]).meta.name, dimensions);
    if (customConditionDate.some((ccd) => ccd.dimensionName === referenceDateField.name)) {
      return { valid: false, referenceDateField, reason: InvalidConfigurationReason.FieldAndCustomConditions };
    }
    return { valid: true, referenceDateField };
  }

  if (conditionDates.length > 0) {
    const referenceDateField = getDimensionDescriptor(defined(conditionDates[0]).dimensionName, dimensions);
    return { valid: true, referenceDateField };
  }

  if (customConditionDate.length > 0) {
    const referenceDateField = getDimensionDescriptor(defined(customConditionDate[0]).dimensionName, dimensions);
    if (customConditionDate.length === 1) return { valid: true, referenceDateField };
    return { valid: false, referenceDateField, reason: InvalidConfigurationReason.MultipleCustomConditions };
  }

  if (measureField.dateRangeType === MeasureDateRange.NetChange) return { valid: true };

  return { valid: false, reason: InvalidConfigurationReason.NoDateFields };
}

function getDateConditions(conditions: ConditionDescriptor[], dimensions: DimensionDescriptor[]) {
  return conditions.filter((c) => {
    const meta = defined(dimensions.find((d) => d.name === c.dimensionName));
    return meta.name === DimensionNames.PostingDate || meta.name === DimensionNames.TransactionDate;
  });
}

function getDateFields(fields: DimensionField[]) {
  return fields.filter(
    (f) => f.meta.name === DimensionNames.PostingDate || f.meta.name === DimensionNames.TransactionDate
  );
}

function getDateCustomConditions(measureField: TabularValueRequest, dimensions: DimensionDescriptor[]) {
  if (!measureField.conditions) return [];
  return measureField.conditions.filter((c) => {
    const meta = defined(dimensions.find((d) => d.name === c.dimensionName));
    return meta.name === DimensionNames.PostingDate || meta.name === DimensionNames.TransactionDate;
  });
}

export function getDimensionFields(fields: ReportField[]) {
  return fields.filter(isDimension);
}

function getMeasureFields(fields: ReportField[], conditions: ConditionField[]) {
  return fields.filter((f) => isMeasure(f) || isAggregation(f)).map((measure) => getMeasureField(conditions, measure));
}

function getDimensionDescriptor(name: string, dimensions: DimensionDescriptor[]) {
  return defined(dimensions.find((d) => d.name === name));
}

function getMeasureField(conditions: ConditionField[], field: ReportField): TabularValueRequest {
  const measureOptions = getMeasure(conditions, field);
  return { ...measureOptions, hideAggregation: field.config.hideAggregation };
}

function getMeasure(conditions: ConditionField[], measure: ReportField): PivotValuesRequest {
  const customConditions = getCustomConditions(measure);
  const linkedConditions = getLinkedConditions(measure, conditions);
  const result = mergeConditions(customConditions, linkedConditions);

  return {
    measureName: measure.meta.name,
    guid: measure.config.guid,
    dateRangeType: measure.config.dateRange || MeasureDateRange.EndingBalance,
    standalone: measure.config.standalone,
    conditions: result,
    customLabel: measure.config.customLabel,
    calculateByField: getMeasureCalculateByField(measure),
    amountType: getMeasureAmountTypeField(measure),
  };
}

export function getMeasureCalculateByField(measure: ReportField) {
  if (isMeasure(measure)) {
    return measure.config.calculateByField || toCalculateBy(measure.meta.units);
  }
  return measure.config.calculateByField;
}

export function getMeasureAmountTypeField(measure: ReportField) {
  if (isMeasure(measure)) {
    return measure.config.amountType || toAmountType(measure.meta.units);
  }
  return measure.config.amountType;
}

function getCustomConditions(value: ReportField) {
  if (!value.config.customConditions) return [];
  return value.config.customConditions
    .filter((cc) => cc.filter.values.length > 0)
    .map((cc): ConditionDescriptor => cc.filter);
}

function getLinkedConditions(value: ReportField, conditions: ConditionField[]) {
  if (!value.config.linkedConditions) return [];
  return value.config.linkedConditions
    .map((lc) => conditions.find((c) => c.config.guid === lc)?.config.filter)
    .filter(
      (filter): filter is ConditionDescriptor =>
        (filter && filter && filter.values && filter.values.length > 0) || false
    )
    .map((c) => cloneDeep(c));
}

function mergeConditions(custom: ConditionDescriptor[], linked: ConditionDescriptor[]) {
  const allConditions = [...custom, ...linked];
  return allConditions;
}

function filterUnsetMandatoryField(config: ConditionConfiguration) {
  return config.mandatory && config.filter.values.length === 0;
}

function areMandatoryFieldsSet(conditions: ConditionField[], customConditions: ConditionConfiguration[]) {
  return (
    conditions.filter((c) => filterUnsetMandatoryField(c.config)).length === 0 &&
    customConditions.filter(filterUnsetMandatoryField).length === 0
  );
}

export type MeasureValidReturnType = {
  valid: boolean;
  referenceDateField?: DimensionDescriptor;
  reason?: InvalidConfigurationReason;
};

export type ConfigurationValidReturnType = ReturnType<typeof isTabularConfigurationValid>;

export function createTabularDefaultSettings(): TabularGeneralSettings {
  return {
    groupSummaryPlace: "Footer",
    showGrandTotal: false,
    hideBlankRows: false,
    hideZeroRows: false,
    showGroupedColumns: false,
  };
}

export function createPivotDefaultSettings(): PivotGeneralSettings {
  return {
    rowsGrandTotal: false,
    hideBlankRows: false,
    hideBlankColumns: false,
    hideZeroRows: false,
    hideZeroColumns: false,
  };
}

export function createDefaultConfiguration(reportType: ReportType): ReportConfiguration {
  const reportNature = getReportNature(reportType);
  if (reportNature === "Tabular") {
    return {
      conditions: [],
      fields: [],
      sort: [],
      grouping: [],
      settings: createTabularDefaultSettings(),
    } as TabularConfiguration;
  } else if (reportNature === "Pivot") {
    return {
      conditions: [],
      rows: [],
      columns: [],
      values: [],
      sort: [],
      settings: createPivotDefaultSettings(),
    } as PivotConfiguration;
  }
  return {
    arguments: [],
    sort: [],
    values: [],
    conditions: [],
    settings: {} as ChartStyleSettings,
  } as ChartConfiguration;
}

function getReportNature(reportType: ReportType): "Tabular" | "Pivot" | "Chart" {
  if (
    reportType === ReportType.PieChart ||
    reportType === ReportType.LineChart ||
    reportType === ReportType.BarChart ||
    reportType === ReportType.AreaChart ||
    reportType === ReportType.DoughnutChart ||
    reportType === ReportType.TreeMapChart
  ) {
    return "Chart";
  }
  if (reportType === ReportType.Pivot) {
    return "Pivot";
  }
  return "Tabular";
}
