import { Box, Stack, Switch, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { JSX, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  AreaItemType,
  ConditionDescriptor,
  DimensionDescriptor,
  ItemDataType,
  MetaItemType,
} from "../../../../../../shared/reporting/api/biClient.types";
import { selectDimensionDictionary } from "../../../../../store/metaDataSlice";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { ConditionFieldsArea } from "../../../common/fields/FieldArea";
import { FieldItemProps } from "../../../common/fields/types/areaFiledItem.types";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createConditionField } from "../../../common/utilities/createFields";
import { extractGuid, extractMeta, isValueField } from "../../../common/utilities/dropFieldContainerHelper";
import { ConditionField, ShowFieldOptionsSettings } from "../../../Types";
import { isAggregation } from "../../../utils/fieldsHelper";
import ConditionFieldOptionPopup from "./ConditionFieldOptionPopup";
import ConditionItem from "./ConditionItem";
import { formatConditionCaption } from "./conditionsHelper";

interface Props {
  area: ConditionFieldsArea;
  readonlyMode?: boolean;
  containerSx?: SxProps<Theme>;
  disableEqualitySelection?: boolean;
}

export default function ConditionsDropFieldsContainer(props: Props) {
  const { area, readonlyMode, containerSx, disableEqualitySelection } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [optionField, setOptionField] = useState<ConditionField>();
  const [detailedView, setDetailedView] = useState(false);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const dictionary = useSelector(selectDimensionDictionary(optionField?.meta.name));

  useEffect(() => {
    if (optionField !== undefined) {
      const field = area.values.find((f) => f.config.guid === optionField.config.guid);
      if (field !== undefined) {
        setOptionField(field);
      }
    }
  }, [area.values, optionField]);

  const handleClosePopup = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onShowOptions = useCallback((settings: ShowFieldOptionsSettings<ConditionField>) => {
    setOptionField(settings.field);
    setAnchorEl(settings.ref);
    setShowOnlySelected(settings.showOnlySelected);
  }, []);

  const canDropRowItem = useCallback((item: DimensionDescriptor | FieldWrapper<ConditionField>) => {
    const meta = extractMeta(item);
    if (!meta) return false;

    if (item.type === AreaItemType.VALUES) {
      const isTheFieldIsAggregation = isValueField(item) && isAggregation(item.field);
      return isTheFieldIsAggregation;
    }

    return meta.itemType === MetaItemType.DIMENSION;
  }, []);

  const onFilterUpdated = useCallback(
    (filter: ConditionDescriptor) => {
      const field = area.values.find((value) => value.config.guid === optionField?.config.guid);
      if (field) {
        area.updateItem(field, { config: { ...field.config, filter } });
      }
      handleClosePopup();
    },
    [area, optionField, handleClosePopup]
  );

  const onUpdateAsParameter = useCallback(
    (field: ConditionField, newValue: boolean) => {
      area.updateItemConfig(field, { ...field.config, parameter: newValue });
    },
    [area]
  );

  const onUpdateAsMandatory = useCallback(
    (field: ConditionField, newValue: boolean) => {
      area.updateItemConfig(field, { ...field.config, mandatory: newValue });
    },
    [area]
  );

  const onUpdateCustomLabel = useCallback(
    (customLabel: string | undefined) => {
      if (optionField) {
        area.updateItem(optionField, { config: { ...optionField.config, customLabel: customLabel } });
      }
    },
    [area, optionField]
  );
  const onCreateItem = useCallback(
    (props: FieldItemProps<ConditionField>) => {
      return (
        <ConditionItem
          field={props.field}
          detailedView={detailedView}
          linked={props.field.hasLinks}
          marked={props.field.config.parameter}
          invalid={props.field.invalid}
          canBeRemoved={props.canBeRemoved}
          onRemoveItem={props.onRemoveItem}
          formatCaption={props.formatCaption}
          optionRef={props.optionRef}
          onShowOptions={props.onShowOptions}
        />
      );
    },
    [detailedView]
  );

  const onFormatCaption = useCallback(
    (field: ConditionField): JSX.Element => {
      return formatConditionCaption(
        field,
        detailedView,
        field.invalid ? theme.palette.text.secondary : undefined,
        disableEqualitySelection
      );
    },
    [detailedView, theme.palette.text.secondary, disableEqualitySelection]
  );
  const handleOnRemoveItem = useCallback(
    (item: ConditionField): void => {
      area.removeItem(item);
      handleClosePopup();
    },
    [area, handleClosePopup]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <Stack direction={"row"} alignItems="center" gap={1}>
        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
          Conditions
        </Typography>
        <HorizontalFill />
        <Typography>Detailed view</Typography>
        <Switch checked={detailedView} onChange={(_, value) => setDetailedView(value)} />
      </Stack>
      <DropFieldContainer
        areaFieldType={AreaItemType.CONDITIONS}
        acceptedDropTypes={[
          AreaItemType.DIMENSION,
          AreaItemType.FIELDS,
          AreaItemType.COLUMNS,
          AreaItemType.ROWS,
          AreaItemType.ARGUMENTS,
          AreaItemType.VALUES,
        ]}
        fields={area.values}
        readonly={readonlyMode}
        containerSx={containerSx}
        getKeyValue={getKeyValue}
        renderListItem={onCreateItem}
        formatCaption={onFormatCaption}
        onAddItem={area.addItem}
        onRemoveItem={area.removeItem}
        onMoveItem={area.moveItem}
        showOptions={onShowOptions}
        createListItem={createConditionField}
        canDropItem={canDropRowItem}
      />
      {anchorEl && optionField && (
        <ConditionFieldOptionPopup
          anchorEl={anchorEl}
          field={optionField}
          showParameter={!readonlyMode}
          showOnlySelectedByDefault={showOnlySelected}
          updateParameter={onUpdateAsParameter}
          updateMandatory={onUpdateAsMandatory}
          updateCustomLabel={onUpdateCustomLabel}
          save={onFilterUpdated}
          onRemoveItem={handleOnRemoveItem}
          canBeRemoved={!readonlyMode}
          dictionary={dictionary || optionField.meta.dictionary}
          resizable={optionField.meta.type !== ItemDataType.Date}
          disableEqualitySelection={disableEqualitySelection}
          close={handleClosePopup}
        />
      )}
    </Box>
  );
}

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  const guid = extractGuid(field);
  if (guid) {
    return guid;
  }
  const meta = extractMeta(field);
  if (meta) {
    return meta.name;
  }
  return "";
}
