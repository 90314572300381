import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Stack, TextField } from "@mui/material";
import { memo } from "react";
import { DragLayerMonitor, useDragLayer } from "react-dnd";
import { FieldWithOrder } from "../../../../../hooks/FieldWithOrder";
import { DraggingBox } from "../../dragLayer/DraggingBox";
import { OptionField } from "./ColumnsOptions.types";

const CustomDragLayerInternal: React.FC = () => {
  const { isDragging, currentOffset, item } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
      item: monitor.getItem() as { field: FieldWithOrder; options: OptionField[] },
    };
  });

  return isDragging && currentOffset ? (
    <DraggingBox
      sx={{
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        height: "37px",
        width: "265px",
        backgroundColor: "#FFF",
      }}
    >
      <CustomDragLayerBody field={item.field} options={item.options} />
    </DraggingBox>
  ) : null;
};

const CustomDragLayerBody = memo((props: { field: FieldWithOrder; options: OptionField[] }) => {
  return (
    <Stack direction="row" sx={{ alignItems: "center", gap: 0.7, borderRadius: "4px" }}>
      <DragIndicatorIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      <TextField
        value={props.options.find((o) => o.id === props.field.field.name)?.label}
        slotProps={{
          input: {
            endAdornment: <ArrowDropDownRoundedIcon color="secondary" />,
          },
        }}
        sx={{ borderColor: "#DDDFE2", flex: 1 }}
      />
    </Stack>
  );
});

CustomDragLayerBody.displayName = "CustomDragLayerBody";

export default memo(CustomDragLayerInternal, () => true);
