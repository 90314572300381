import { Box, Link, Typography } from "@mui/material";
import { DataGridPremium, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import React from "react";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { ConditionField, EMPTY_CROSSFILTERED_CONDITION_VALUES } from "../../../Types";
import { unique } from "../../preview/utilities/unique";
import { isCrossFilterToBeRefreshed } from "./conditionsHelper";
import { DimensionDictionary } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  field: ConditionField;
  values: DimensionDictionary[];
  selectedValues: unknown[];
  readonly?: boolean;
  onChanged: (selectedValues: string[]) => void;
}
export default function TextFilterGrid(props: Props) {
  const { field, values, selectedValues, readonly, onChanged } = props;

  const isLoading = isCrossFilterToBeRefreshed(field);

  const columns = React.useMemo(() => getColumns(field), [field]);

  const refinedSelectedValues = React.useMemo(
    () =>
      values
        .filter((v) => selectedValues.includes(v[field.meta.keyFieldName]))
        .map((row): number => row["id"] as number)
        .filter((v): v is number => v !== undefined),
    [selectedValues, values, field.meta.keyFieldName]
  );

  const onSelectionChanged = React.useCallback(
    (rowNumbers: GridRowSelectionModel) => {
      onChanged(getNewSelection(rowNumbers as number[], values, field.meta.keyFieldName));
    },
    [values, field.meta.keyFieldName, onChanged]
  );

  return (
    <ScrollableFlexContainer sx={{ minHeight: "255px" }}>
      <DataGridPremium<DimensionDictionary>
        columns={columns}
        rows={values}
        sx={(theme) => ({
          "--DataGrid-rowBorderColor": theme.palette.divider,
          border: "1px solid " + theme.palette.divider,
          ".MuiDataGrid-columnHeaders": {
            borderBottom: "none",
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "unset",
          },
          ".MuiDataGrid-columnHeader:focus-within": {
            outline: "unset",
          },
          ".MuiDataGrid-virtualScroller": {
            pointerEvents: isLoading ? "none" : "inherit",
          },
          ".MuiDataGrid-row": {
            ".MuiDataGrid-cell": {
              borderTop: "none",
            },
          },
          ".MuiButtonBase-root.MuiCheckbox-root": {
            p: 0.75,
          },
          ".MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop": {
            border: "none",
          },
        })}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: field.meta.keyFieldName,
                sort: "asc",
              },
            ],
          },
        }}
        rowHeight={24}
        checkboxSelection={!readonly}
        rowSelectionModel={refinedSelectedValues}
        disableRowSelectionOnClick={readonly}
        onRowSelectionModelChange={onSelectionChanged}
        hideFooter
        columnHeaderHeight={30}
        slots={{
          loadingOverlay: InlineLoader,
          noRowsOverlay: () => <NoRows field={field} />,
        }}
        loading={isLoading}
      />
    </ScrollableFlexContainer>
  );
}

function getNewSelection(rowNumbers: number[], values: DimensionDictionary[], keyField: string) {
  return values
    .filter((row) => rowNumbers.includes(row["id"] as number))
    .map((row) => row[keyField])
    .filter((v): v is string => v !== undefined)
    .filter(unique);
}

function NoRows({ field }: { field: ConditionField }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      {field.crossFilter && field.crossFilter.values === EMPTY_CROSSFILTERED_CONDITION_VALUES ? (
        [
          <Typography key="title">There is no data available.</Typography>,
          <Typography key="body" color="textSecondary" px={1}>
            Please review the cross-filter configuration or refer to{" "}
            <Link
              href="https://help.entrilia.com/en/articles/8570469-cross-filtering-in-reports"
              target="_blank"
              rel="noreferrer"
            >
              the documentation for more information.
            </Link>
          </Typography>,
        ]
      ) : (
        <Typography key="title">No Rows</Typography>
      )}
    </Box>
  );
}

function getColumns(field: ConditionField) {
  return field.meta.availableFields.map(
    (f) =>
      ({
        field: f,
        disableColumnMenu: true,
        flex: 1,
        disableReorder: true,
        valueFormatter: (value, _, col) => value ?? getFallbackValue(value, col.field === field.meta.keyFieldName),
      }) as GridColDef
  );
}

function getFallbackValue(value: unknown, isKeyFieldColumn: boolean) {
  if (isKeyFieldColumn) {
    return "No Value";
  }

  return value;
}
