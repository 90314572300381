import { Typography, TypographyProps } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { MutableRefObject } from "react";
import { Grouping, ItemDataType, TabularDataCell } from "../../../../../../../shared/reporting/api/biClient.types.ts";
import { TabularRow } from "../../../../../../api/biApi.types.ts";
import {
  compareValues,
  getCellFromRow,
  getFormattedValueWithFallback,
  getGroupingValue,
  isTopLevelGroup,
  refineComparisonResult,
} from "./buildDimensionColumnHelper.ts";
import DimensionCellDisplayValue from "./DimensionCellDisplayValue.tsx";

export type DimensionColumnField = {
  title: string;
  guid: string;
  fieldType: ItemDataType | undefined | null;
  groupingDateBy?: Grouping | undefined | null;
};

export function buildDimensionColumn(
  api: MutableRefObject<GridApiPremium>,
  field: DimensionColumnField,
  getFieldTitle: (guid: string) => string,
  showGroupedColumns: boolean,
  isGroupingField: (fieldGuid: string) => boolean
) {
  return {
    field: field.guid,
    headerName: field.title,
    minWidth: 175,
    maxWidth: 500,
    groupable: field.fieldType === ItemDataType.General || field.fieldType === ItemDataType.Date,
    aggregable: false,
    type: "string",
    align: field.fieldType === ItemDataType.Numeric ? "right" : "left",
    headerAlign: field.fieldType === ItemDataType.Numeric ? "right" : "left",
    filterable: false,
    hideable: false,
    pinnable: false,
    renderHeader: () => <Typography variant="subtitle2">{getFieldTitle(field.guid)}</Typography>,
    sortComparator: (v1: TabularDataCell | undefined, v2: TabularDataCell | undefined, cell1, cell2) => {
      const isGroupRow = cell1.rowNode.type === "group";

      if (isGroupRow && !!api.current?.getRowNode) {
        const cell1value = api.current.getRowNode(cell1.id);
        const cell2value = api.current.getRowNode(cell2.id);

        if (cell1value?.type === "group" && cell2value?.type === "group") {
          const comparisonResult = compareValues(cell1value.groupingKey, cell2value.groupingKey);

          if (!isTopLevelGroup(cell1value.depth)) {
            const sortModel = api.current.getSortModel();
            const currentGroupSorting =
              sortModel.find((sort) => sort.field === cell1value.groupingField)?.sort ?? "asc";
            const topLevelGroupSorting = sortModel.find((sort) => sort.field === field.guid)?.sort ?? "asc";

            return refineComparisonResult(currentGroupSorting, topLevelGroupSorting, comparisonResult);
          }

          return comparisonResult;
        }
      }

      return compareValues(v1?.value, v2?.value);
    },
    renderCell: (params) => {
      if (params.rowNode.type === "group") {
        if (params.rowNode.groupingField === field.guid) {
          let childRowId = params.rowNode.children[0];
          let cell: TabularDataCell | undefined;
          while (!cell && childRowId) {
            const node = params.api.getRowNode(childRowId);
            if (node?.type === "leaf") {
              const childRow = params.api.getRow<TabularRow>(childRowId);
              if (childRow) {
                cell = getCellFromRow(childRow, field.guid);
                if (cell === undefined) {
                  // Data is not loaded yet
                  break;
                }
              }
            } else if (node?.type === "group") {
              childRowId = node?.children[0];
            } else {
              return "";
            }
          }

          // Grouping row cell
          if (cell) {
            const typographyProps: TypographyProps = showGroupedColumns
              ? { variant: "subtitle2" }
              : { variant: "body1" };
            return (
              <DimensionCellDisplayValue
                cell={cell}
                formattedValue={getFormattedValueWithFallback(cell)}
                typographyProps={typographyProps}
              />
            );
          }

          return params.formattedValue;
        }
        return null;
      }
      // Leaf row cell
      const cell = getCellFromRow(params?.row, params?.field);
      const isGroupLeafValue = showGroupedColumns && isGroupingField(field.guid);
      const typographyProps: TypographyProps = isGroupLeafValue
        ? { color: "text.secondary" }
        : { color: "text.primary" };
      return (
        <DimensionCellDisplayValue
          cell={cell}
          formattedValue={cell?.formattedValue}
          typographyProps={typographyProps}
        />
      );
    },
    groupingValueGetter: (_, row, column) => getGroupingValue(getCellFromRow(row, column.field)),
    valueGetter: (_, row, column) => getCellFromRow(row, column.field),
  } as GridColDef;
}
