import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import { selectDimensionsForCurrentReportType, selectDimensionsStructure } from "../../../../store/metaDataSlice";
import DimensionsTree from "./dimensions/DimensionsTree";
import Measures from "./measures/Measures";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";

export const FieldsComponent = () => {
  const [dimensionsExpanded, setDimensionsExpanded] = useState(true);
  const [searchText, setSearchText] = useState("");

  const dimensions = useSelector(selectDimensionsForCurrentReportType);
  const dimensionsStructure = useSelector(selectDimensionsStructure);

  const filteredDimensions = useMemo(() => {
    if (searchText === "") {
      return dimensions;
    }
    return dimensions.filter((d) => d.caption.toLowerCase().indexOf(searchText) > -1);
  }, [searchText, dimensions]);

  const revertDimensionExpanded = useCallback(() => setDimensionsExpanded(!dimensionsExpanded), [dimensionsExpanded]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        pt: 1,
        gap: "1rem",
      }}
    >
      <Box px="1.3rem">
        <SearchField onSearch={(text) => setSearchText(text?.toLowerCase())} debounceTimeMs={500} fullWidth />
      </Box>
      <ScrollableFlexContainer scrollContainerSx={{ display: "flex", flexDirection: "column", gap: 1, px: "1.3rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography sx={{ flexGrow: 1, color: "text.primary" }} variant="subtitle2">
              Dimensions
            </Typography>
            <IconButton color="secondary" onClick={revertDimensionExpanded}>
              {dimensionsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Collapse
            orientation="vertical"
            in={dimensionsExpanded}
            sx={{ "& .MuiCollapse-wrapperInner": { display: "block" } }}
          >
            <DimensionsTree fields={filteredDimensions} structure={dimensionsStructure} />
          </Collapse>
        </Box>
        <Measures searchText={searchText} />
      </ScrollableFlexContainer>
    </Box>
  );
};

export default FieldsComponent;
